<template>
  <div>
    <a-modal
      @ok="prePrintBulletin"
      @cancel="closeModal"
      v-model="visibleFinalBulletin"
      :title="$t('bulletin.finalBulletin')"
      :closable="false"
      :width="700"
      :height="700"
      :key="'bulletinDate'"
    >
      <div>
        <a-form :form="form" layout="vertical">
          <div class="row">
            <div class="col-3">
              <label>{{ $t("emploi.listeEnseignants") }}</label>
            </div>
            <div class="col">
              <a-form-item class="m-0">
                <a-select
                  v-decorator="[
                    'teachers',
                    {
                      rules: [
                        {
                          required: true,
                          message: $t('choisir.enseignant'),
                        },
                      ],
                    },
                  ]"
                  id="selectClass"
                  show-search
                  mode="tags"
                  :placeholder="$t('emploi.listeEnseignants')"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  style="width: 400px"
                >
                  <a-select-option
                    v-for="teacher in teachers"
                    :key="teacher._id"
                    :value="
                      teacher.employee.firstName +
                      ' ' +
                      teacher.employee.lastName
                    "
                  >
                    {{
                      teacher.employee.firstName +
                      " " +
                      teacher.employee.lastName
                    }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label>{{ $t("bulletin.date") }}</label>
            </div>
            <div class="col">
              <a-form-item class="m-0">
                <a-date-picker
                  style="width: 400px"
                  :allowClear="false"
                  @change="
                    (val) => {
                      bulletinDate = val;
                    }
                  "
                  :default-value="moment(new Date())"
                />
              </a-form-item>
            </div>
          </div>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script>
/*eslint-disable */
import moment from "moment";
import apiClient from "@/services/axios";
import { mapState } from "vuex";
import JsPDF from "jspdf";

export default {
  props: ["visibleFinalBulletin", "studentID"],
  data() {
    return {
      bulletinDate: new Date(),
      teachers: [],
      form: this.$form.createForm(this),
      schoolDetails: {},
      schoolarYearName: ".... / ....",
      bulletin: {},
      decisionConseilParams: [],
    };
  },
  computed: {
    ...mapState(["settings", "user"]),
  },
  components: {},
  async created() {
    this.schoolDetails = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0];
    let { start, end } = this.settings.schoolarYears.find(
      (el) => el._id == this.settings.activeSchoolarYear
    );

    start = new Date(start).getFullYear();
    end = new Date(end).getFullYear();

    this.schoolarYearName = end + " / " + start;

    await apiClient
      .post("/teachers/filter", {
        query: { status: "active" },
      })
      .then((res) => (this.teachers = res.data));
  },
  methods: {
    moment,
    closeModal() {
      this.$emit("closeFinalBulletin");
    },

    async prePrintBulletin() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.closeModal();
          this.$emit("printLoading", true);
          await apiClient
            .get("/specificBulletin/final/" + this.studentID)
            .then((res) => (this.bulletin = res.data));

          this.printBulletin(values);
        }
      });
    },

    async printBulletin(values) {
      const doc = new JsPDF();
      const ctx = doc.context2d;
      const pdf_width = doc.internal.pageSize.width;
      let width = 0;
      // background header
      const img = new Image();
      img.src = "./resources/images/bg1.png";
      doc.addImage(img, "png", 0, 0, pdf_width, 28);

      //stamp
      let stamp = this.settings.base_url + "/" + this.schoolDetails.stamp;
      const stampImage = new Image(30);
      if (stamp) stampImage.src = stamp;
      if (stamp) {
        try {
          await this.waitLoadingImage(stampImage);
        } catch (e) {}
      }
      //end stamp

      // Header texts
      doc.setFont("PT Bold Heading", "Bold");
      doc.setFontSize(13);
      let text = "المندوبيّة الجهويّة للتربيّة";
      doc.setTextColor(99, 87, 91);
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 15, 12);
      console.log(this.schoolDetails);
      text = this.schoolDetails.delegeRegional
        ? "بـ" + this.schoolDetails.delegeRegional
        : ".....................................بـ";
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 15, 22);

      doc.setFont("Amiri", "normal");

      doc.setTextColor(67, 62, 63);
      doc.setFontSize(12);
      text = this.schoolDetails.ArabicName
        ? " المدرسة الابتدائيّة " + this.schoolDetails.ArabicName
        : "................................................... : المدرسة الابتدائيّة";
      width = doc.getTextWidth(text);
      doc.text(text, 105 - width - 15, 12);
      text = ` ${this.schoolarYearName}  : السنة الدراسيّة`;
      width = doc.getTextWidth(text);
      doc.text(text, 105 - width - 15, 20);

      // ending header line
      doc.setDrawColor(52, 172, 215);
      doc.setLineWidth(0.8);
      doc.line(0, 28, pdf_width, 28);

      let studentLabel =
        this.bulletin.gender === "male" ? " : التلميذ" : " : التلميذة";

      // student name
      doc.setFont("Amiri", "normal");
      doc.setFontSize(12);
      doc.setTextColor(99, 87, 91);
      text = studentLabel;
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 12, 39);

      text = this.bulletin.fullName;
      doc.setFont("Amiri", "Bold");
      doc.setFontSize(13);
      let width2 = doc.getTextWidth(text);

      doc.text(text, pdf_width - width - width2 - 12, 39);
      doc.setFont("Amiri", "normal");
      doc.setFontSize(12);

      // student classroom
      text = " : القسم";
      doc.setTextColor(99, 87, 91);
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 100, 39);
      text = this.bulletin.classroomName;
      width2 = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - width2 - 100, 39);

      // title (نتائج نهائية)
      doc.setFillColor(180, 217, 233);
      this.roundRect(
        ctx,
        10,
        55,
        190,
        15,
        { upperRight: 15 },
        true,
        true,
        [187, 217, 233]
      );
      doc.setFont("PT Bold Heading", "Bold");
      doc.setFontSize(22);
      text = "النتائج النهائية";
      doc.setTextColor(51, 171, 214);
      width = doc.getTextWidth(text);
      doc.text(text, 107.5 - width / 2, 65);

      // trimester 1
      doc.setFillColor(182, 217, 235);
      doc.setDrawColor(51, 171, 214);
      this.roundRect(
        ctx,
        155.2,
        78.3,
        45.5,
        12,
        { upperRight: 12 },
        true,
        true,
        [51, 171, 214]
      );
      doc.setFillColor(0xff, 0xff, 0xff);
      doc.setLineWidth(0.4);
      doc.rect(120, 78, 35, 12, "FD");
      doc.setFont("Amiri", "Bold");
      doc.setFontSize(14);
      doc.setTextColor(51, 171, 214);
      text = "معدل الثّلاثي الأول";
      width = doc.getTextWidth(text);
      doc.text(text, 178 - width / 2, 85);
      doc.setTextColor(67, 62, 63);
      text = this.bulletin.moyenneOne;
      width = doc.getTextWidth(text);
      doc.text(text, 137 - width / 2, 85);

      // trimester 2
      doc.setFillColor(182, 217, 235);
      doc.setDrawColor(51, 171, 214);
      doc.setLineWidth(0.4);
      doc.rect(155, 90, 46, 12, "FD");
      doc.setFillColor(0xff, 0xff, 0xff);
      doc.rect(120, 90, 35, 12, "FD");
      doc.setFont("Amiri", "Bold");
      doc.setFontSize(14);
      doc.setTextColor(51, 171, 214);
      text = "معدل الثّلاثي الثّاني";
      width = doc.getTextWidth(text);
      doc.text(text, 178 - width / 2, 97);
      doc.setTextColor(67, 62, 63);
      text = this.bulletin.moyenneTwo;
      width = doc.getTextWidth(text);
      doc.text(text, 137 - width / 2, 97);

      // trimester 3
      doc.setFillColor(182, 217, 235);
      doc.setDrawColor(51, 171, 214);
      doc.setLineWidth(0.4);
      doc.rect(155, 102, 46, 12, "FD");
      doc.setFillColor(0xff, 0xff, 0xff);
      doc.rect(120, 102, 35, 12, "FD");
      doc.setFont("Amiri", "Bold");
      doc.setFontSize(14);
      doc.setTextColor(51, 171, 214);
      text = "معدل الثّلاثي الثّالث";
      width = doc.getTextWidth(text);
      doc.text(text, 178 - width / 2, 109);
      doc.setTextColor(67, 62, 63);
      text = this.bulletin.moyenneThree;
      width = doc.getTextWidth(text);
      doc.text(text, 137 - width / 2, 109);

      // annual moyenne
      doc.setFillColor(49, 125, 175);
      this.roundRect(
        ctx,
        77,
        78.3,
        35,
        12,
        { upperRight: 12 },
        true,
        true,
        [51, 171, 214]
      );
      doc.setFillColor(0xff, 0xff, 0xff);
      doc.setDrawColor(51, 171, 214);
      doc.setLineWidth(0.4);
      doc.rect(76.7, 90.3, 35.5, 24, "FD");
      doc.setFont("Amiri", "Bold");
      doc.setFontSize(14);
      text = "المعدل السنوي";
      width = doc.getTextWidth(text);
      doc.setTextColor(0xff, 0xff, 0xff);
      doc.text(text, 93 - width / 2, 86);
      doc.setTextColor(67, 62, 63);
      doc.setFontSize(16);
      text = this.bulletin.finalMoyenne;
      width = doc.getTextWidth(text);
      doc.text(text, 95 - width / 2, 102);

      // max annual moyenne
      doc.setFillColor(182, 217, 235);
      doc.setDrawColor(51, 171, 214);
      doc.setLineWidth(0.4);
      doc.rect(43, 78.3, 32, 12, "FD");
      doc.setFillColor(0xff, 0xff, 0xff);
      doc.rect(43, 90.3, 32, 24, "FD");
      doc.setFont("Amiri", "Bold");
      doc.setFontSize(12);
      doc.setTextColor(51, 171, 214);
      text = "أعلى\nمعدل سنوي بالقسم";
      width = doc.getTextWidth(text);
      doc.text(text, 77 - width / 2, 83, "center");
      doc.setTextColor(67, 62, 63);
      doc.setFontSize(16);
      text = this.bulletin.maxFinalMoyenne;
      width = doc.getTextWidth(text);
      doc.text(text, 60 - width / 2, 102);

      // min annual moyenne
      doc.setFillColor(182, 217, 235);
      doc.setDrawColor(51, 171, 214);
      doc.setLineWidth(0.4);
      doc.rect(10, 78.3, 31, 12, "FD");
      doc.setFillColor(0xff, 0xff, 0xff);
      doc.rect(10, 90.3, 31, 24, "FD");
      doc.setFont("Amiri", "Bold");
      doc.setFontSize(12);
      doc.setTextColor(51, 171, 214);
      text = "أدنى\nمعدل سنوي بالقسم";
      width = doc.getTextWidth(text);
      doc.text(text, 43 - width / 2, 83, "center");
      doc.setTextColor(67, 62, 63);
      doc.setFontSize(16);
      text = this.bulletin.minFinalMoyenne;
      width = doc.getTextWidth(text);
      doc.text(text, 25 - width / 2, 102);

      //council decision
      let councilY = 125;
      doc.setFillColor(0xff, 0xff, 0xff);
      doc.rect(10, councilY + 5, 190, 35, "FD");
      doc.setFillColor(182, 217, 235);
      this.roundRect(
        ctx,
        70,
        councilY,
        70,
        11,
        { upperRight: 11 },
        true,
        true,
        [51, 171, 214]
      );
      doc.setFont("Amiri", "Bold");
      doc.setFontSize(16);
      doc.setTextColor(51, 171, 214);
      text = "قرار مجلس القسم";
      width = doc.getTextWidth(text);
      doc.text(text, 120 - width / 2, councilY + 6, "center");
      doc.setTextColor(67, 62, 63);
      doc.setFontSize(16);
      text = ""; //this.getDecisionByMoyenne(this.bulletin.finalMoyenne);
      width = doc.getTextWidth(text);
      doc.text(text, 105 - width / 2, councilY + 23);

      // teachers list
      // teachers list - signs
      let teachersY = 175;
      let teachersWidth = 26.5;
      if (values.teachers.length > 3)
        teachersWidth += 8.45 * (values.teachers.length - 3) + 0.5;
      doc.setFillColor(182, 217, 235);
      this.roundRect(
        ctx,
        10.2,
        teachersY + 0.5,
        59.5,
        9,
        { upperRight: 9 },
        true,
        true,
        [51, 171, 214]
      );
      doc.setFillColor(0xff, 0xff, 0xff);
      doc.setLineWidth(0.4);
      doc.rect(10, teachersY + 10, 60, teachersWidth, "FD");
      doc.setFont("Amiri", "Bold");
      doc.setFontSize(15);
      doc.setTextColor(51, 171, 214);
      text = "الإمضاء";
      width = doc.getTextWidth(text);
      doc.text(text, 42 - width / 2, teachersY + 6, "center");

      // teachers list - teachers rect
      doc.setFillColor(182, 217, 235);
      this.roundRect(
        ctx,
        70.2,
        teachersY + 0.5,
        129.5,
        9,
        { upperRight: 9 },
        true,
        true,
        [51, 171, 214]
      );
      doc.setFillColor(0xff, 0xff, 0xff);
      doc.setLineWidth(0.4);
      doc.rect(70, teachersY + 10, 130, teachersWidth, "FD");
      doc.setFont("Amiri", "normal");
      doc.setFontSize(15);
      doc.setTextColor(51, 171, 214);
      text = "المدرّسون";
      width = doc.getTextWidth(text);
      doc.text(text, 140 - width / 2, teachersY + 6.5, "center");
      doc.setTextColor(67, 62, 63);
      doc.setFontSize(12);

      // teachers list - table
      const body = [
        ["", ""],
        ["", ""],
        ["", ""],
      ];
      for (let i = 0; i < values.teachers.length; i++) {
        if (body[i]) body[i][1] = values.teachers[i];
        else body.push(["", values.teachers[i]]);
      }
      doc.autoTable({
        startY: teachersY + 10,
        theme: "grid",
        styles: {
          font: "Amiri",
          halign: "center",
          valign: "middle",
          fontStyle: "normal",
          fontSize: 13,
          lineColor: [182, 217, 235],
          lineWidth: 0.1,
        },
        cellPadding: 0,
        body,
        margin: { left: 10.2 },
        didDrawPage: function (data) {
          // Reseting top margin. The change will be reflected only after print the first page.
          data.settings.margin.top = 10;
        },
        columnStyles: {
          0: { cellWidth: 60 },
          1: { cellWidth: 129.7 },
        },
      });

      //headmaster
      let headmasterY = 230 + (teachersWidth - 37);
      doc.setFillColor(0xff, 0xff, 0xff);
      doc.rect(10, headmasterY + 5, 190, 45, "FD");
      doc.setFillColor(182, 217, 235);
      this.roundRect(
        ctx,
        70,
        headmasterY,
        70,
        11,
        { upperRight: 11 },
        true,
        true,
        [51, 171, 214]
      );
      doc.setFont("Amiri", "Bold");
      doc.setFontSize(16);
      doc.setTextColor(51, 171, 214);
      text = "مدير)ة( المدرسة";
      width = doc.getTextWidth(text);
      doc.text(text, 120 - width / 2, headmasterY + 6, "center");
      // student name
      doc.setFont("Amiri", "normal");
      doc.setFontSize(12);
      doc.setTextColor(99, 87, 91);
      text = " : الإسم و اللقب";
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 12, headmasterY + 25);
      doc.setFontSize(14);
      text = this.schoolDetails.headMaster;
      doc.text(text, 173, headmasterY + 25, "right");

      doc.text(
        moment(this.bulletinDate).format("YYYY/MM/DD"),
        125,
        headmasterY + 45,
        "right"
      );

      //stamp
      if (stampImage && stampImage.src) {
        try {
          doc.addImage(stampImage, "PNG", 20, headmasterY + 17, 30, 30);
        } catch (e) {}
      }
      //end stamp

      this.$emit("printLoading", false);
      doc.save(`Bulletin annuel ${this.bulletin.fullName}.pdf`);
    },
    waitLoadingImage(image) {
      return new Promise((res, rej) => {
        image.onload = (t) => {
          res();
        };
        image.onerror = (e) => {
          rej();
        };
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    roundRect(
      ctx,
      x,
      y,
      width,
      height,
      radius,
      fill,
      stroke,
      border = [70, 187, 239]
    ) {
      const cornerRadius = {
        upperLeft: 0,
        upperRight: 0,
        lowerLeft: 0,
        lowerRight: 0,
      };
      if (typeof stroke == "undefined") {
        stroke = true;
      }
      if (typeof radius === "object") {
        for (const side in radius) {
          cornerRadius[side] = radius[side];
        }
      }

      ctx.lineWidth = 1;
      ctx.strokeStyle = `rgb(${border[0]}, ${border[1]}, ${border[2]})`;

      ctx.beginPath();
      ctx.moveTo(x + cornerRadius.upperLeft, y);
      ctx.lineTo(x + width - cornerRadius.upperRight, y);
      ctx.quadraticCurveTo(
        x + width,
        y,
        x + width,
        y + cornerRadius.upperRight
      );
      ctx.lineTo(x + width, y + height - cornerRadius.lowerRight);
      ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - cornerRadius.lowerRight,
        y + height
      );
      ctx.lineTo(x + cornerRadius.lowerLeft, y + height);
      ctx.quadraticCurveTo(
        x,
        y + height,
        x,
        y + height - cornerRadius.lowerLeft
      );
      ctx.lineTo(x, y + cornerRadius.upperLeft);
      ctx.quadraticCurveTo(x, y, x + cornerRadius.upperLeft, y);
      ctx.closePath();
      if (stroke) {
        ctx.stroke();
      }
      if (fill) {
        ctx.fill();
      }
    },
  },
};
</script>
